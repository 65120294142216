:root {
    --primary-100: #fef0f4;
    --primary-200: #fdd2dd;
    --primary-300: #fcb4c6;
    --primary-400: #fa86a4;
    --primary-500: #f9688d;
    --primary-600: #e05e7f;
    --primary-700: #ae4963;
    --primary-800: #7d3447;
    --primary-900: #32151c;
    --lightpink-100: #fffafa;
    --lightpink-200: #fde8e8;
    --lightpink-300: #fcdede;
    --lightpink-400: #fbd0d0;
    --lightpink-500: #e2bbbb;
    --lightpink-600: #c9a6a6;
    --lightpink-700: #977d7d;
    --lightpink-800: #4b3e3e;
    --lightpink-900: #191515;
    --ecru-100: #fffff4;
    --ecru-200: #ffffe9;
    --ecru-300: #fffede;
    --ecru-400: #fffed3;
    --ecru-500: #FFFEC8;
    --ecru-550: #fbf8af;
    --ecru-600: #e7e5a5;
    --ecru-700: #999878;
    --ecru-800: #666650;
    --ecru-900: #333328;
    --liver-050: #fafafa;
    --liver-100: #eeedee;
    --liver-200: #cbc8cd;
    --liver-300: #98919b;
    --liver-350: #8a848d;
    --liver-400: #645a6a;
    --liver-500: #534859;
    --liver-600: #423a47;
    --liver-700: #3a323e;
    --liver-800: #2a242d;
    --liver-900: #19161b;
    --red-100: #f4d5da;
    --red-500: #b21e34;
    --green-100: #EAFFF7;
    --green-500: #24966B;
    --black: #121212;
    --white: #FFFFFF;
    --shadow-low: 0 2px 8px 0 rgba(0,0,0,0.1);
    --shadow-medium: 0 4px 16px 0 rgba(0,0,0,0.12);
    --shadow-high: 0 8px 36px 0 rgba(0,0,0,0.15);
    --text-shadow: 0 0 1px #777;
}